var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled, keyframes } from "../stitches.config";
const active = keyframes({
    from: { bottom: 0, left: 0 },
    to: { bottom: -2, left: -2 },
});
const hoverIn = keyframes({
    from: { outlineWidth: 0 },
    to: { outlineWidth: "2" },
});
export const StyledLink = styled("a", {
    color: "$text",
    position: "relative",
    "&:active": {
        bottom: -2,
        left: -2,
    },
    "&:focus": {
        outline: "blue 2px solid",
    },
    variants: {
        button: {
            true: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                fontFamily: "$normal",
                border: "$borderWidths$button solid $buttonBorder",
                background: "$buttonBackgroud",
                color: "$buttonText",
                padding: "$2 $3",
                fontSize: "$2",
                cursor: "pointer",
                textDecoration: "none",
                textAlign: "center",
                width: "100%",
                "&:hover:not(:focus)": {
                    outlineColor: "blue",
                    outlineStyle: "solid",
                    animation: `${hoverIn} 100ms ease-out`,
                },
                "&:active": {
                    animation: `${active} 100ms ease-out`,
                    bottom: -2,
                    left: -2,
                },
                "&:disabled": {
                    cursor: "auto",
                    opacity: "0.6",
                },
            },
        },
    },
});
export const Link = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return _jsx(StyledLink, Object.assign({}, rest, { children: children }));
};
