import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useState, createContext, useEffect, useContext, } from "react";
import { darkTheme } from "../stitches.config";
if (typeof window !== "undefined") {
    if (window.localStorage.getItem("ctt-dark-mode") === "on") {
        window.document.body.classList.add(darkTheme);
    }
    else {
        window.document.body.classList.remove(darkTheme);
    }
}
const DarkModeContext = createContext([false, () => false, () => { }]);
export const DarkModeContextProvider = ({ children, }) => {
    const [darkMode, setDarkModeState] = useState(() => {
        return false;
    });
    useEffect(() => {
        if (localStorage.getItem("ctt-dark-mode") === "on") {
            document.body.classList.add(darkTheme);
            setDarkModeState(true);
        }
        else {
            document.body.classList.remove(darkTheme);
            setDarkModeState(false);
        }
    }, []);
    const toggleDarkMode = useCallback(() => {
        setDarkModeState((s) => {
            if (s) {
                localStorage.setItem("ctt-dark-mode", "off");
                document.body.classList.remove(darkTheme);
                return false;
            }
            else {
                localStorage.setItem("ctt-dark-mode", "on");
                document.body.classList.add(darkTheme);
                return true;
            }
        });
    }, []);
    const setDarkMode = useCallback((value) => {
        if (!value) {
            localStorage.setItem("ctt-dark-mode", "off");
            document.body.classList.remove(darkTheme);
            setDarkModeState(false);
        }
        else {
            localStorage.setItem("ctt-dark-mode", "on");
            document.body.classList.add(darkTheme);
            setDarkModeState(true);
        }
    }, []);
    const value = [darkMode, toggleDarkMode, setDarkMode];
    return (_jsx(DarkModeContext.Provider, Object.assign({ value: value }, { children: children })));
};
export const useDarkMode = () => {
    return useContext(DarkModeContext);
};
