import { createStitches, createTheme } from "@stitches/react";
export const { styled, css, theme, getCssText, keyframes, globalCss } = createStitches({
    theme: {
        colors: {
            up: "rgb(50,205,50)",
            down: "rgb(255,0,0)",
            focus: "rgb(0,0,255)",
            background: "white",
            backgroundUp: "white",
            backgroundDown: "white",
            backgroundUpHighlighted: "$up",
            backgroundDownHighlighted: "$down",
            text: "black",
            textUp: "$up",
            textDown: "$down",
            textUpHighlighted: "white",
            textDownHighlighted: "white",
            buttonBackground: "transparent",
            buttonBorder: "black",
            buttonText: "black",
            inputBackground: "transparent",
            inputBorder: "black",
            inputText: "black",
            error: "$down",
            errorBackground: "rgba(255, 0, 0, 0.3)",
            success: "$up",
            successBackground: "rgba(50,205,50, 0.3)",
            info: "$focus",
            infoBackground: "rgba(0,0,255, 0.3)",
        },
        space: {
            1: "4px",
            2: "8px",
            3: "16px",
            4: "24px",
            5: "32px",
        },
        fontSizes: {
            1: "18px",
            2: "22px",
            3: "32px",
            4: "42px",
            5: "72px",
        },
        fonts: {
            normal: "'Poppins', sans-serif",
        },
        fontWeights: {
            normal: "normal",
            bold: "bold",
        },
        lineHeights: {
            1: "18px",
            2: "22px",
            3: "22px",
            4: "42px",
            5: "72px",
        },
        letterSpacings: {},
        sizes: {
            containedMax: "700px",
        },
        borderWidths: {
            button: "2px",
            input: "2px",
        },
        borderStyles: {},
        radii: {},
        shadows: {},
        zIndices: {},
        transitions: {},
    },
    utils: {
        m: (value) => ({
            marginTop: value,
            marginBottom: value,
            marginLeft: value,
            marginRight: value,
        }),
        mt: (value) => ({
            marginTop: value,
        }),
        mr: (value) => ({
            marginRight: value,
        }),
        mb: (value) => ({
            marginBottom: value,
        }),
        ml: (value) => ({
            marginLeft: value,
        }),
        mx: (value) => ({
            marginLeft: value,
            marginRight: value,
        }),
        my: (value) => ({
            marginTop: value,
            marginBottom: value,
        }),
        p: (value) => ({
            paddingTop: value,
            paddingBottom: value,
            paddingLeft: value,
            paddingRight: value,
        }),
        pt: (value) => ({
            paddingTop: value,
        }),
        pr: (value) => ({
            paddingRight: value,
        }),
        pb: (value) => ({
            paddingBottom: value,
        }),
        pl: (value) => ({
            paddingLeft: value,
        }),
        px: (value) => ({
            paddingLeft: value,
            paddingRight: value,
        }),
        py: (value) => ({
            paddingTop: value,
            paddingBottom: value,
        }),
        // A property for applying width/height together
        size: (value) => ({
            width: value,
            height: value,
        }),
    },
});
export const darkTheme = createTheme({
    colors: {
        background: "black",
        backgroundUp: "black",
        backgroundDown: "black",
        backgroundUpHighlighted: "darkgreen",
        backgroundDownHighlighted: "darkred",
        text: "lightgray",
        textUp: "darkgreen",
        textDown: "darkred",
        textUpHighlighted: "lightgray",
        textDownHighlighted: "lightgray",
        buttonBackground: "transparent",
        buttonBorder: "white",
        buttonText: "white",
        inputBackground: "transparent",
        inputBorder: "white",
        inputText: "white",
        error: "red",
    },
});
export const globalStyles = globalCss({
    "html,body": {
        fontFamily: "$normal",
        fontSize: "$1",
        padding: 0,
        margin: 0,
        minHeight: "100vh",
        overflow: "scroll",
        "overflow-x": "hidden",
        "-ms-overflow-style": "none" /* Internet Explorer 10+ */,
        "scrollbar-width": "none" /* Firefox */,
        backgroundColor: "$background",
        color: "$text",
        // fontFamily: "'Lexend Deca', sans-serif",
    },
    "*, *::before, *::after": {
        boxSizing: "border-box",
    },
    "a:not(:disabled)": {
        cursor: "pointer",
    },
    "::-webkit-scrollbar": {
        width: 0 /* Remove scrollbar space */,
        background: "transparent" /* Optional: just make scrollbar invisible */,
    },
});
