import React, { useState } from "react";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";
import { IdProvider } from "@radix-ui/react-id";
import { AppProps } from "next/app";

import { usePanelbear } from "../hooks/panelBear";
import { DarkModeContextProvider } from "ui/dist/hooks/darkMode";
import { UserContextProvider } from "../components/UserContext";
import { LinkAsComponentProvider } from "ui/dist/contexts/LinkAsComponent";
import { AppLink } from "../components/AppLink";

// function AuthListener() {
//   useEffect(() => {
//     const { data: authListener } = supabasePublic.auth.onAuthStateChange(
//       (event, session) => {
//         console.log({ event, session });
//         // if (event === 'PASSWORD_RECOVERY') setAuthLayout('forgotten_password')
//         // if (event === 'USER_UPDATED') setTimeout(() => setAuthLayout('sign_in'), 1000)

//         // Send session to /api/auth route to set the auth cookie.
//         fetch("/api/auth", {
//           method: "POST",
//           headers: new Headers({ "Content-Type": "application/json" }),
//           credentials: "same-origin",
//           body: JSON.stringify({ event, session }),
//         }).then((res) => res.json());
//       }
//     );

//     return () => {
//       authListener.unsubscribe();
//     };
//   }, []);

//   return null;
// }

function MyApp({
    Component,
    pageProps,
}: {
    Component: AppProps["Component"] & {
        getLayout?: (
            page: React.ReactNode,
            pageProps: AppProps["pageProps"]
        ) => React.ReactNode;
        Layout: React.ComponentType<any>;
    };
    pageProps: AppProps["pageProps"];
}) {
    usePanelbear(process.env.NEXT_PUBLIC_PANELBEAR_PUBLIC_KEY, {
        // Uncomment to allow sending events on localhost, and log to console too.
        // debug: true
        scriptSrc: "/bear.js",
    });

    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        staleTime: 5000,
                    },
                },
            })
    );

    const initialUser = { user: pageProps?.user, session: pageProps?.session };

    const getLayout = Component.getLayout || ((page: React.ReactNode) => page);

    return (
        <>
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
            />
            <IdProvider>
                <DarkModeContextProvider>
                    <LinkAsComponentProvider value={AppLink}>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <UserContextProvider initialValue={initialUser}>
                                    {Component.Layout ? (
                                        <Component.Layout>
                                            <Component {...pageProps} />
                                        </Component.Layout>
                                    ) : (
                                        getLayout(
                                            <Component {...pageProps} />,
                                            pageProps
                                        )
                                    )}
                                </UserContextProvider>
                            </Hydrate>
                        </QueryClientProvider>
                    </LinkAsComponentProvider>
                </DarkModeContextProvider>
            </IdProvider>
        </>
    );
}

export default MyApp;
