var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const getPrice = (client) => ({ exchange, coin }) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield client
        .from("symbols")
        .select(`
        id,
        name,
        exchanges (
          name
        ),
        last,
        close,
        updated_at
    `)
        .match({
        name: coin,
        exchanges: {
            name: exchange,
        },
    })
        .limit(1)
        .single();
    if (error) {
        throw error;
    }
    const change = data.last - data.close;
    const percentChange = (change / data.close) * 100;
    return Object.assign(Object.assign({}, data), { change,
        percentChange, updatedAt: Date.parse(data.updated_at) });
});
