import { createClient } from "@supabase/supabase-js";

import { createSupabaseServiceClient } from "services/dist/supabase";

export const supabasePublic = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL as string,
    process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_KEY as string,
    {
        autoRefreshToken: true,
        persistSession: true,
    }
);

export const supabaseServicePublic =
    createSupabaseServiceClient(supabasePublic);
