import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from "react";
import { Link } from "../../components/Link";
export const LinkAsComponentContext = createContext(Link);
export const useLinkAsComponent = () => {
    const context = useContext(LinkAsComponentContext);
    return context;
};
export const LinkAsComponentProvider = ({ value = Link, children, }) => {
    return (_jsx(LinkAsComponentContext.Provider, Object.assign({ value: value }, { children: children })));
};
