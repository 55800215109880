import NextLink from "next/link";
import { Link, LinkProps } from "ui/dist/components/Link";

export const AppLink = ({ href, button, children, onClick }: LinkProps) => {
    return (
        <NextLink href={href || "#"} passHref>
            <Link button={button} onClick={onClick}>
                {children}
            </Link>
        </NextLink>
    );
};
