var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export const getExchangeSymbols = (kucoinClient) => ({ id, name, }) => __awaiter(void 0, void 0, void 0, function* () {
    let exchange = "";
    switch (id) {
        case 1:
            exchange = "kucoin";
            break;
        case 2:
            exchange = "coinbase";
            break;
    }
    let result;
    if (exchange === "kucoin") {
        const _result = yield kucoinClient.rest.Market.Symbols.getSymbolsList();
        result = _result.data.map((r) => r.symbol);
    }
    if (exchange === "coinbase") {
        const _result = yield fetch(`https://api.pro.coinbase.com/products`).then((r) => r.json());
        result = _result.map((r) => r.id);
    }
    if (!result) {
        throw new Error("Exchange is not supported");
    }
    return result;
});
