import { addDeviceUpdateNotification } from "../addDeviceUpdateNotification";
import { addSymbol } from "../addSymbol";
import { getDevice } from "../getDevice";
import { getDeviceCode } from "../getDeviceCode";
import { getDeviceSymbolsAndSettingsByDeviceCodeId } from "../getDeviceSymbolsAndSettingsByDeviceCodeId";
import { getExchanges } from "../getExchanges";
import { getExchangeSymbols } from "../getExchangeSymbols";
import { getOrAddSymbol } from "../getOrAddSymbol";
import { getPrice } from "../getPrice";
import { getSymbol } from "../getSymbol";
import { getSymbols } from "../getSymbols";
import { getTicker } from "../getTicker";
import { getTickers } from "../getTickers";
import { getUserProfile } from "../getUserProfile";
import { updateDevice } from "../updateDevice";
import { updateDeviceCode } from "../updateDeviceCode";
export const createSupabaseServiceClient = (supabaseClient) => {
    return {
        addDeviceUpdateNotification: addDeviceUpdateNotification(supabaseClient),
        addSymbol: addSymbol(supabaseClient),
        getDevice: getDevice(supabaseClient),
        getDeviceCode: getDeviceCode(supabaseClient),
        getDeviceSymbolsAndSettingsByDeviceCodeId: getDeviceSymbolsAndSettingsByDeviceCodeId(supabaseClient),
        getExchanges: getExchanges(supabaseClient),
        getExchangeSymbols: getExchangeSymbols(supabaseClient),
        getOrAddSymbol: getOrAddSymbol(supabaseClient),
        getPrice: getPrice(supabaseClient),
        getSymbol: getSymbol(supabaseClient),
        getSymbols: getSymbols(supabaseClient),
        getTicker: getTicker(supabaseClient),
        getTickers: getTickers(supabaseClient),
        getUserProfile: getUserProfile(supabaseClient),
        updateDevice: updateDevice(supabaseClient),
        updateDeviceCode: updateDeviceCode(supabaseClient),
    };
};
