var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export const getSymbols = (supabaseClient) => (match) => __awaiter(void 0, void 0, void 0, function* () {
    const { data, error } = yield supabaseClient
        .from("symbols")
        .select("*")
        .match(match);
    if (error) {
        throw error;
    }
    const _data = data.map((_a) => {
        var { updated_at } = _a, remainingData = __rest(_a, ["updated_at"]);
        const change = remainingData.last - remainingData.close;
        const percentChange = (change / remainingData.close) * 100;
        const updatedAt = Date.parse(updated_at);
        return Object.assign(Object.assign({}, remainingData), { change, percentChange, updatedAt });
    });
    return _data;
});
